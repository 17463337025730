require("packs/plugins/plugins.bundle.js")
require("packs/metronic/scripts.bundle.js")
require("packs/plugins/datatables/datatables.bundle")
require("packs/plugins/datatables/responsive")
require("packs/plugins/fullcalendar/fullcalendar.bundle.js")
//require("packs/plugins/gmaps.js")
//require("packs/metronic/dashboard.js")
require("packs/metronic/toastr.js")
require("packs/metronic/neo.js.erb")
require("packs/plugins/select2.js")
require("packs/metronic/summernote.js")
require("packs/plugins/bootstrap-select.js")